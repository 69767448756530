import { useCallback, useMemo } from 'react';

export const CHANGELOG_STORAGE_KEY = 'changelog';

const DATA = [
  {
    version: '2.11.0',
    htmlContent: `<p><i>Need help? Contact us at </i><a href="mailto:support@bigheartphilanthropy.org"><i>support@bigheartphilanthropy.org</i></a><i>&nbsp;</i></p>`,
  },
  {
    version: '2.10.0',
    htmlContent: `<p><strong>Welcome to the app!&nbsp;</strong></p><p>&nbsp;</p><p>You have the opportunity to customize your experience on the app.<br>&nbsp;</p><ul><li>Express yourself with a profile picture - you’ll make a great impression!</li><li>Build out your profile by adding your phone number, birthday, and timezone. This information is safe and shared only with your organization’s administrators.</li><li>Read and agree to our standard consents.&nbsp;</li><li>Turn on notifications to get timely reminders and alerts about your upcoming appointments, tasks, and events. You’ll stay in the loop and never miss a beat!</li><li>Syncing your calendar with our app is a quick and easy way to stay on top of your appointments and tasks. Don’t miss out on important events - sync your calendar now!<br>&nbsp;</li></ul><p>&nbsp;</p><figure class="image" imagewidth="1920" imageheight="1080"><img style="aspect-ratio:1920/1080;" src="https://d19yly73nh0efj.cloudfront.net/5766iiw5gsczzbwmxvkgtzi4v6fh" width="1920" height="1080"></figure><p>&nbsp;</p><p>&nbsp;</p><p><br><strong>Organization Admins!&nbsp;</strong></p><p>&nbsp;</p><p>Managing content and settings just got easier!<br>&nbsp;</p><ul><li>You will now receive an email notification when someone in your organization reports another user or their posted content.&nbsp;</li><li>Need to update your organization’s info or settings? With a quick click from your profile, you can easily manage your organization settings in the Admin Console, within Organizations.&nbsp;</li></ul><p>&nbsp;</p><figure class="image" imagewidth="518" imageheight="982"><img style="aspect-ratio:518/982;" src="https://d19yly73nh0efj.cloudfront.net/34i1oerl9v705vicafzsruv1zl67" width="518" height="982"></figure><p><br><i>Need help? Contact us at </i><a href="mailto:support@bigheartphilanthropy.org"><i>support@bigheartphilanthropy.org</i></a><i>&nbsp;</i></p>`,
  },
  {
    version: '2.9.0',
    htmlContent: `<p><strong>We’ve improved the To-Dos experience, including responding to the event invitation directly in To-Dos. When it’s time to join the event, you can also join the event from To-Dos on the Home screen.&nbsp;</strong></p><p><br>Please note that the events widget has been hidden from the web homepage as those actions are now included in the To-Dos widget.</p><p>&nbsp;</p><figure class="image" imagewidth="500" imageheight="339"><img style="aspect-ratio:323px;/219px;;" src="https://lh7-us.googleusercontent.com/slidesz/AGV_vUcdOa2Z7OggXpwkIR9_-NY0_4OFIQIfTOn8vJB15AOWPqdV17uuu5khii5btt_XMJauLGObPI2wFflDYZFO0OuD2HmZdNXskWR3-VbaI7lqY3YrWVKyjSc2I-xPnHSjR30cW3slvBggHHVEDEmUoxTLxrJ9A4xA=s2048?key=zleTFX-qb8EHTO3FODLb3w" width="323px;" height="219px;"></figure><p>&nbsp;</p><p>And, your personal profile has a refreshed design.</p><p><br><strong>Admins!</strong>&nbsp;</p><p>&nbsp;</p><p>The admin console has new navigation as we prepare for a new design. Find the Dashboard under Analytics, and User Management has been renamed to All Members.</p><p><br><i>Need help? Contact us at </i><a href="mailto:support@bigheartphilanthropy.org"><i>support@bigheartphilanthropy.org</i></a><i>&nbsp;</i></p>`,
  },
  {
    version: '2.8.0',
    htmlContent: `<p><strong>To-Dos has a refreshed design with new capabilities!</strong></p><p>&nbsp;</p><p>Event to-dos enable you to join live events in one click.</p><p>&nbsp;</p><p>Program posts are now included in To-Dos. You can complete the To-Do by marking the Program post as Read.</p><p>&nbsp;</p><figure class="image" imagewidth="844" imageheight="714"><img style="aspect-ratio:303px;/257px;;" src="https://lh7-us.googleusercontent.com/ODM7aGiJeCqeKeGCkWTl2XZ13ixMtywXfE7n6uUEtE3eih6VX12cbeyGlSQP9upUDPBAzPdK2dP0qE1xDekTTRHjh32MV9vs55chRu5zuyUi-3svyoyRpOdA_sG6CWsKiFHc_MOObYK469DA08lMArHmYw=s2048" width="303px;" height="257px;"></figure><p><br><strong>Admins!</strong>&nbsp;</p><p>&nbsp;</p><p>Highlight your most important programs by designating them as featured. After Private Label Organization Admins set the program as featured in the Admin Console, your program members will see their featured program to-dos at the top of their To-Do list on their homepage.&nbsp;</p><p>&nbsp;</p><p>We've added titles to program content creation, optimizing the view of program posts and polls in members' to-do lists.&nbsp;</p><p><br><i>Need help? Contact us at </i><a href="mailto:support@bigheartphilanthropy.org"><i>support@bigheartphilanthropy.org</i></a><i>&nbsp;</i></p>`,
  },
  {
    version: '2.7.0',
    htmlContent: `<p><strong>Custom Channels are here!</strong></p><p>&nbsp;</p><figure class="media"><oembed url="https://docs.google.com/presentation/d/1kW8RkJ7voVKw_5pHOXjHBmOVqP9jfPtjfkKLDjtqpYA/edit?usp=sharing"></oembed></figure><p>&nbsp;</p><p><br><strong>Forgot your Password?</strong> You’ll now be prompted to reset your password if you enter your password incorrectly 5 times.</p><p><br>In addition to copying a link to a post, you can now also <strong>copy a link to a comment</strong>.</p><p><br><strong>Admins!</strong> The admin console has a fresh, new look.</p><p>&nbsp;</p><figure class="image" imagewidth="3584" imageheight="2014"><img style="aspect-ratio:3584/2014;" src="https://d19yly73nh0efj.cloudfront.net/xufvwb2zbnvpb492g9m627dxhiin" width="3584" height="2014"></figure><p><br><i>Need help? Contact us at </i><a href="mailto:support@bigheartphilanthropy.org"><i>support@bigheartphilanthropy.org</i></a><i>&nbsp;</i></p>`,
  },
  {
    version: '2.6.0',
    htmlContent: `<p>Welcome to a more personalized experience.&nbsp;</p><p>&nbsp;</p><ul><li>Hello! When you open the app, you’re greeted by name and will see imagery that corresponds to the time of day.</li></ul><p>&nbsp;</p><ul><li>You’ve got mail! The notification settings page has an improved design. Badge (in-app) notifications have been improved, as they are automatically marked as “seen” after you have viewed the content.</li></ul><p>&nbsp;</p><p>Content Creators: To better protect community privacy, we’ve limited Post and Event creation to one community. In addition, we’ve improved the action buttons to Repost and Share posts.<br>&nbsp;</p><p>Organization admins:&nbsp;<br>&nbsp;</p><ul><li>Program creation has been moved from Channels to Joined Programs.&nbsp;<br>&nbsp;</li><li>And, you can now assign Tags to Communities, Programs, Events, and Campaigns you create. These Tags will be used in an upcoming release to create your Custom Channels.</li></ul><p><br>Need help? Contact us at <a href="mailto:support@bigheartphilanthropy.org">support@bigheartphilanthropy.org</a>&nbsp;</p>`,
  },
  {
    version: '2.5.0',
    htmlContent: `<p>We now have release notes, which are visible the first time you open the app after a new release!</p><p><br>Navigating the BigHeart app just got easier.</p><ul><li>Go to “<a href="/channels">Channels</a>” to find your organization's Programs and Communities that you haven’t joined yet.</li><li>Go to “My Content” and you’ll find everything that is yours:&nbsp;<ul><li>Your Joined: <a href="/joined-communities">Communities</a>, <a href="/joined-programs">Programs</a>, and <a href="/campaigns/listing">Campaigns</a></li><li>Your <a href="/bookmarks">Bookmarks</a></li><li>Your <a href="/journal">Journal</a></li></ul></li></ul><p>&nbsp;</p><p>BigHeart app users: Our web address is changing! To complete our rebranding from Powered by Action to BigHeart Philanthropy, you can now find us at bigheartapp.org &nbsp;and email us at support@bigheartphilanthropy.org! Don’t worry about remembering these new addresses, pba.network and support@poweredbyaction.org will forward to our new addresses.</p><p>&nbsp;</p><p>We’ve reduced the number of email notifications to improve your experience.<br>&nbsp;</p><p>Youtube live links (like https://youtube.com/live/) are supported in comments and posts.&nbsp;</p><p>For your video to be viewable in-app, ensure you have enabled “Allow Embedding” in video details.&nbsp;<br>&nbsp;</p><p>Program Admins: Adding members to a program is now labeled as Enrolling a member in a program.<br>&nbsp;</p><p>Program Authors: Creating a program is now located within Channels &gt;&gt; see All Programs.</p><p><br>Need help? Contact us at <a href="mailto:support@bigheartphilanthropy.org">support@bigheartphilanthropy.org</a>&nbsp;<br>&nbsp;</p>`,
  },
];

export const useChangelogData = () => {
  const storedVersion = localStorage.getItem(CHANGELOG_STORAGE_KEY);
  const latestWhatIsNewVersion = DATA[0].version;

  const updateLatestWhatIsNewVersion = useCallback(() => {
    localStorage.setItem(CHANGELOG_STORAGE_KEY, latestWhatIsNewVersion);
  }, [latestWhatIsNewVersion]);

  return useMemo(
    () => ({
      data: DATA,
      storedVersion,
      latestWhatIsNewVersion,
      updateLatestWhatIsNewVersion,
    }),
    [latestWhatIsNewVersion, storedVersion, updateLatestWhatIsNewVersion],
  );
};

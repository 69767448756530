import { createModel } from '@rematch/core';
import { denormalize, normalize } from 'normalizr';
import { select } from '@rematch/select';

import { ContentObjectService } from '../services/ContentObjectService';
import { ContentObjectSchema, EventSchema, ReplySchema } from './schemas';
import { dispatch, getState } from '../store';
import { getPossibleFeedKeys } from './feed.helpers';
import { showAlert } from '../atoms/alert';
import { update } from './helpers';

export { getFeedKey } from './feed.helpers';

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [ContentObjectSchema] : ContentObjectSchema);
  dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

function getParentEntityKey(parentId, eventDetailParentComment) {
  const existEvent = select.entities.existEntity(getState(), {
    entity: 'events',
    id: parentId,
  });

  const existComment = select.entities.existEntity(getState(), {
    entity: 'replies',
    id: parentId,
  });

  return existComment ? 'replies' : existEvent && eventDetailParentComment ? 'events' : 'content_objects';
}

async function handleBookmark({ object, feed }, dispatchFeed) {
  if (object.bookmarked) {
    if (feed) {
      getPossibleFeedKeys({ ...object, feedType: feed }).forEach((feed) => {
        dispatchFeed.destroyObject({ object: object, feed: feed });
      });
    }
    return ContentObjectService.unbookmark(object.id);
  }
  return ContentObjectService.bookmark(object.id);
}

const INITIAL_STATE = {
  loading: {},
};

export const model = createModel({
  name: 'feed',

  state: INITIAL_STATE,

  selectors: {
    get(state, feed) {
      return denormalize(state[feed] || [], [ContentObjectSchema], getState().entities);
    },

    findById(state, id) {
      return denormalize(id, ContentObjectSchema, getState().entities);
    },

    loading(state, { feed }) {
      return state.loading[feed] === true;
    },
  },

  reducers: {
    'session/reset': () => INITIAL_STATE,

    reset() {
      return INITIAL_STATE;
    },

    setObjects(state, { ids, feed }) {
      return update(state, { [feed]: { $autoArray: { $set: ids } } });
    },

    pushObjects(state, { ids, feed }) {
      return update(state, { [feed]: { $autoArray: { $push: ids } } });
    },

    setCurrentObjectId(state, { id }) {
      return update(state, { currentObjectId: { $set: id } });
    },

    unshiftObjects(state, { ids, feed }) {
      return update(state, { [feed]: { $autoArray: { $unshift: ids } } });
    },

    destroyObject(state, { object, feed }) {
      return update(state, {
        [feed]: (arr) => (arr || []).filter((id) => id !== object.id),
      });
    },

    setLoading(state, { feed, loading }) {
      return update(state, { loading: { [feed]: { $set: loading } } });
    },
  },

  effects: (dispatch) => ({
    async getAsync({ params, feed, bookmarked, journal }) {
      this.setLoading({ feed, loading: true });
      const { community_id, program_id, reset = false, ...filteredParams } = params;
      const response = community_id
        ? await ContentObjectService.getCommunityFeed(community_id, filteredParams)
        : program_id
        ? await ContentObjectService.getCurriculumFeed(program_id, filteredParams)
        : bookmarked
        ? await ContentObjectService.getBookmarkedFeed(params)
        : journal
        ? await ContentObjectService.getJournalFeed(params)
        : await ContentObjectService.getGlobalFeed(params);

      if (response.ok) {
        if (reset) {
          this.setObjects({ ids: normalizeData(response.data), feed: feed });
        } else {
          this.pushObjects({ ids: normalizeData(response.data), feed: feed });
        }
      }

      this.setLoading({ feed, loading: false });

      return response;
    },

    removeAll({ feed }) {
      this.setObjects({ ids: [], feed });
    },

    async findAsync({ feed, postId }) {
      const response = await ContentObjectService.find(postId);

      if (response.ok) {
        this.pushObjects({ ids: normalizeData([response.data]), feed: feed });
      } else if (response?.data?.message) {
        showAlert({ type: 'error', message: response.data.message });
      }

      return response;
    },

    async findPostAsync({ feed, postId }) {
      const response = await ContentObjectService.findPost(postId);

      if (response.ok) {
        this.pushObjects({ ids: normalizeData([response.data]), feed: feed });
        this.setCurrentObjectId({ id: response.data.id });
      } else if (response?.data?.message) {
        showAlert({ type: 'error', message: response.data.message });
      }

      return response;
    },

    async findOrderPostAsync({ feed, postId }) {
      const response = await ContentObjectService.findOrderPost(postId);

      if (response.ok) {
        this.pushObjects({ ids: normalizeData([response.data]), feed: feed });
        this.setCurrentObjectId({ id: response.data.id });
      } else if (response?.data?.message) {
        showAlert({ type: 'error', message: response.data.message });
      }

      return response;
    },

    async findPollAsync({ feed, postId }) {
      const response = await ContentObjectService.findPoll(postId);

      if (response.ok) {
        this.pushObjects({ ids: normalizeData([response.data]), feed: feed });
        this.setCurrentObjectId({ id: response.data.id });
      } else if (response?.data?.message) {
        showAlert({ type: 'error', message: response.data.message });
      }

      return response;
    },

    async findActivityAsync({ feed, postId }) {
      const response = await ContentObjectService.findActivity(postId);

      if (response.ok) {
        this.pushObjects({ ids: normalizeData([response.data]), feed: feed });
        this.setCurrentObjectId({ id: response.data.id });
      } else if (response?.data?.message) {
        showAlert({ type: 'error', message: response.data.message });
      }

      return response;
    },

    async findKudosAsync({ feed, postId }) {
      const response = await ContentObjectService.findKudos(postId);

      if (response.ok) {
        this.pushObjects({ ids: normalizeData([response.data]), feed: feed });
        this.setCurrentObjectId({ id: response.data.id });
      } else if (response?.data?.message) {
        showAlert({ type: 'error', message: response.data.message });
      }

      return response;
    },

    async reloadAsync({ postId }) {
      const response = await ContentObjectService.find(postId);

      if (response.ok) {
        normalizeData([response.data]);
      } else if (response?.data?.message) {
        showAlert({ type: 'error', message: response.data.message });
      }

      return response;
    },

    storeContentObjectData(data) {
      return normalizeData([data]);
    },

    async findEventContentObjectAsync({ feed, eventId }) {
      const response = await ContentObjectService.findEventContentObject(eventId);

      if (response.ok) {
        this.pushObjects({ ids: normalizeData([response.data]), feed: feed });
      }

      return response;
    },

    async likeAsync({ object }) {
      dispatch.entities.updateEntity({
        [object.type === 'comment' ? 'replies' : 'content_objects']: {
          [object.id]: {
            likes_count: (val) => (object.liked ? val - 1 : val + 1),
            liked: (val) => !val,
          },
        },
      });

      if (object.liked) {
        return await ContentObjectService.unlike(object.id);
      }

      return await ContentObjectService.like(object.id);
    },

    storeContentObjectLike({ object }) {
      dispatch.entities.updateEntity({
        [object.type === 'comment' ? 'replies' : 'content_objects']: {
          [object.id]: {
            likes_count: (val) => (object.liked ? val - 1 : val + 1),
            liked: (val) => !val,
          },
        },
      });
    },

    async bookmarkCampaignAsync({ object, campaign, feed }) {
      if (campaign) {
        dispatch.entities.updateEntity({
          campaigns: {
            [campaign.id]: {
              content_object: {
                bookmarked: (val) => !val,
              },
            },
          },
        });
      }

      return handleBookmark({ object: object, feed: feed }, dispatch.feed);
    },

    async bookmarkAsync({ object, feed }) {
      if (object.type) {
        dispatch.entities.updateEntity({
          [object.type === 'comment' ? 'replies' : 'content_objects']: {
            [object.id]: {
              bookmarked: (val) => !val,
            },
          },
        });
      }

      return handleBookmark({ object: object, feed: feed }, dispatch.feed);
    },

    async deleteAsync({ object, originalItem, eventDetail, feed }) {
      if (object.type === 'comment') {
        const eventDetailParentComment =
          eventDetail && object.top_parent_type === 'event' && object.top_parent_id === object.parent_id;
        const parentId = eventDetailParentComment ? object.top_parent_shareable_id : object.parent_id;
        const parentEntityKey = getParentEntityKey(parentId, eventDetailParentComment);
        const numberOfReplies = object?.replies?.length ?? 0;
        const decrementCommentsCount = { $apply: (slice) => slice - numberOfReplies - 1 };

        dispatch.entities.updateEntity({
          [parentEntityKey]: {
            [parentId]: {
              replies: (arr) => arr.filter((id) => id !== object.id),
              comments_count: decrementCommentsCount,
            },
          },
          ...(parentEntityKey === 'replies' &&
            !eventDetail && {
              content_objects: {
                [originalItem.id]: {
                  comments_count: { $apply: (slice) => slice - 1 },
                },
              },
            }),
        });
      } else if (feed) {
        this.destroyObject({ object: object, feed: feed });
      } else {
        getPossibleFeedKeys({ ...object, feedType: feed }).forEach((feed) => {
          this.destroyObject({ object: object, feed: feed });
        });
      }

      switch (object.type) {
        case 'comment':
          return ContentObjectService.deleteComment(object.id);
        case 'activity':
          return ContentObjectService.deleteActivity(object.activity.id);
        case 'poll':
          return ContentObjectService.deletePoll(object.poll.id);
        case 'event':
          return ContentObjectService.deleteEvent(object.event.id);
        case 'withings_order_post':
          return ContentObjectService.deleteOrderPost(object.withings_order_post.id);
        case 'kudo':
          return ContentObjectService.deleteKudos(object.kudo.id);
        default:
          return ContentObjectService.delete(object.post.id);
      }
    },

    async reportAsync({ object, onSuccess, onError }) {
      const objectId = object.event_content_object ? object.event_content_object.id : object.id;

      const response = await ContentObjectService.reportAbuse(objectId);
      if (response.ok) {
        onSuccess?.();
      } else {
        onError?.();
      }
      return response;
    },

    async createAsync({ draft, parent_id, feed, comment, eventDetail }) {
      const response = comment
        ? await ContentObjectService.createComment({ ...draft, parent_id: parent_id })
        : await ContentObjectService.create({ ...draft, parent_id: parent_id });

      if (response.ok) {
        if (response.data.type === 'comment') {
          const normalizedData = normalize(response.data, ReplySchema);
          const eventDetailParentComment =
            eventDetail &&
            response.data.top_parent_type === 'event' &&
            response.data.top_parent_id === response.data.parent_id;

          const parentId = eventDetailParentComment ? response.data.top_parent_shareable_id : response.data.parent_id;
          const parentEntityKey = getParentEntityKey(parentId, eventDetailParentComment);
          const incrementCommentsCount = { $apply: (slice) => slice + 1 };

          dispatch.entities.mergeEntities(normalizedData.entities);

          dispatch.entities.updateEntity({
            [parentEntityKey]: {
              [parentId]: {
                replies: { $push: [normalizedData.result] },
                comments_count: incrementCommentsCount,
              },
            },
            ...(parentEntityKey === 'replies' && {
              content_objects: {
                [response.data.top_parent_id]: {
                  comments_count: incrementCommentsCount,
                },
              },
            }),
          });
        } else if (response.data.contentable_type === 'Program') {
          this.unshiftObjects({ ids: normalizeData([response.data]), feed: feed });
        } else {
          getPossibleFeedKeys({ ...response.data, feedType: feed }).forEach((feed) => {
            this.unshiftObjects({ ids: normalizeData([response.data]), feed: feed });
          });
        }
      }

      return response;
    },

    reloadContentObjects({ responseData }) {
      getPossibleFeedKeys(responseData).forEach((feed) => {
        this.unshiftObjects({ ids: normalizeData([responseData]), feed: feed });
      });
    },

    async createPoll({ poll }) {
      const response = await ContentObjectService.createPoll(poll);

      if (response.ok) {
        getPossibleFeedKeys(response.data).forEach((feed) => {
          this.unshiftObjects({ ids: normalizeData([response.data]), feed: feed });
        });
      }

      return response;
    },

    async createKudos({ kudo }) {
      const response = await ContentObjectService.createKudos(kudo);

      if (response.ok) {
        getPossibleFeedKeys(response.data).forEach((feed) => {
          this.unshiftObjects({ ids: normalizeData([response.data]), feed: feed });
        });
      }

      return response;
    },

    async createEvent({ event, feed }) {
      const normalizedData = normalize(event, EventSchema);

      dispatch.entities.mergeEntities(normalizedData.entities);
      this.unshiftObjects({
        ids: Object.keys(normalizedData.entities.content_objects),
        feed: feed,
      });

      return event;
    },

    async updateAsync({ object }) {
      object.program = object.program?.id || object.program;
      const response = object.comment
        ? await ContentObjectService.updateComment(object.comment)
        : await ContentObjectService.update(object);

      if (response.ok) {
        if (response.data.type === 'comment') {
          const normalizedData = normalize(response.data, ReplySchema);

          dispatch.entities.mergeEntities(normalizedData.entities);
          dispatch.entities.updateEntity({});
        } else {
          normalizeData(response.data);
        }
      }

      return response;
    },

    async updateKudos({ kudo }) {
      const response = await ContentObjectService.updateKudos(kudo);

      if (response.ok) {
        normalizeData(response.data);
      }

      return response;
    },

    async sortCommentsAsync({ object, sort }) {
      const response = await ContentObjectService.find(object.id, { sort });

      if (response.ok) {
        normalizeData(response.data);
      }

      return response;
    },

    // START: Comments backward compatibility

    storeComment({ responseData }) {
      const normalizedData = normalize(responseData, ReplySchema);
      dispatch.entities.mergeEntities(normalizedData.entities);

      const isEventComment =
        responseData.top_parent_type === 'event' && responseData.top_parent_id === responseData.parent_id;

      const incrementCommentsCount = { $apply: (slice) => slice + 1 };

      // Try store also to event replies
      if (isEventComment) {
        const parentIdEvent = responseData.top_parent_shareable_id;
        const parentEntityKeyEvent = getParentEntityKeySafe(parentIdEvent);

        // Event was found as detail
        if (parentEntityKeyEvent) {
          dispatch.entities.updateEntity({
            [parentEntityKeyEvent]: {
              [parentIdEvent]: {
                replies: { $push: [normalizedData.result] },
                comments_count: incrementCommentsCount,
              },
            },
          });
        }
      }

      const parentId = responseData.parent_id;
      const parentEntityKey = getParentEntityKeySafe(parentId);

      if (parentEntityKey) {
        dispatch.entities.updateEntity({
          [parentEntityKey]: {
            [parentId]: {
              replies: { $push: [normalizedData.result] },
              comments_count: incrementCommentsCount,
            },
          },
          ...(parentEntityKey === 'replies' && {
            content_objects: {
              [responseData.top_parent_id]: {
                comments_count: incrementCommentsCount,
              },
            },
          }),
        });
      }
    },

    updateComment({ responseData }) {
      const normalizedData = normalize(responseData, ReplySchema);
      dispatch.entities.mergeEntities(normalizedData.entities);
      dispatch.entities.updateEntity({});
    },

    deleteComment({ comment }) {
      const isEventComment = comment.top_parent_type === 'event' && comment.top_parent_id === comment.parent_id;

      const amountOfReplies = comment.replies?.length ?? 0;
      const decrementCommentsCount = { $apply: (slice) => slice - (1 + amountOfReplies) };

      if (isEventComment) {
        const parentIdEvent = comment.top_parent_shareable_id;
        const parentEntityKeyEvent = getParentEntityKeySafe(parentIdEvent);

        // Event was found as detail
        if (parentEntityKeyEvent) {
          dispatch.entities.updateEntity({
            [parentEntityKeyEvent]: {
              [parentIdEvent]: {
                replies: (arr) => arr.filter((id) => id !== comment.id),
                comments_count: decrementCommentsCount,
              },
            },
          });
        }
      }

      const parentId = comment.parent_id;
      const parentEntityKey = getParentEntityKeySafe(parentId);

      if (parentEntityKey) {
        dispatch.entities.updateEntity({
          [parentEntityKey]: {
            [parentId]: {
              replies: (arr) => arr.filter((id) => id !== comment.id),
              comments_count: decrementCommentsCount,
            },
          },
          ...(parentEntityKey === 'replies' && {
            content_objects: {
              [comment.top_parent_id]: {
                comments_count: decrementCommentsCount,
              },
            },
          }),
        });
      }
    },

    // END: Comments backward compatibility
  }),
});
